// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const showSlice = createSlice({
  name: "show",
  initialState: {},

  reducers: {
    searchShows: (state, action) => {
      state.searchResults = action.payload.shows
    },
    getProviderShows: (state, action) => {

      state.numberOfResults = action.payload.numberOfResults
      state.totalCount = action.payload.totalCount

      const shows = [...action.payload.shows]

      state.shows = {}
      shows
        // .sort((a, b) => {
        //   const nameA = a.name.toUpperCase() // ignore upper and lowercase
        //   const nameB = b.name.toUpperCase() // ignore upper and lowercase
        //   if (nameA < nameB) {
        //     return -1
        //   }
        //   if (nameA > nameB) {
        //     return 1
        //   }

        //   // names must be equal
        //   return 0
        // })
        .forEach((show) => {
          state.shows[show.id] = show
        })
    },
    getProviderEpisodes: (state, action) => {
      // state.episodes = action.payload.episodes

      if (action.payload.episodes) {
        const episodes = [...action.payload.episodes]
        state.episodes = {}

        episodes
          .sort((a, b) => {
            if (a.seasonNumber < b.seasonNumber) return -1
            if (a.seasonNumber > b.seasonNumber) return 1
            // Sort on name
            if (a.episodeNumber < b.episodeNumber) return -1
            if (a.episodeNumber > b.episodeNumber) return 1
            return 0
          })
          .forEach((episode) => {
            state.episodes[episode.id] = episode
          })
      } else {
        // if episodes is dispatched as null - I use it for the loader
        state.episodes = action.payload.episodes
      }
    },

    getShowDetails: (state, action) => {
      state.selectedShow = action.payload.show
    },

    // episodeIndexToDelete: (state, action) => {
    //   state.episodes.splice(action.payload, 1)
    // },
    addTmdbShow: (state, action) => {
      if (state.shows[action.payload.show.id]) {
        delete state.shows[action.payload.show.id]
      }

      state.shows = {
        [action.payload.show.id]: action.payload.show,
        ...state.shows
      }
      toast.success(
        `Show ${action.payload.show.name} was added or updated successfully!`
      )

      // state.addedShow = action.payload.show
    },
    addCustomShow: (state, action) => {
      if (state.shows[action.payload.show.id]) {
        delete state.shows[action.payload.show.id]
      }

      state.shows = {
        [action.payload.show.id]: action.payload.show,
        ...state.shows
      }
      toast.success(
        `Show ${action.payload.show.name} was added or updated successfully!`
      )

      // state.addedShow = action.payload.show
    },
    addEpisode: (state, action) => {
      // if (!state.episodes) {
      //   state.episodes = []
      // }

      toast.success(
        `Episode ${action.payload.episode.name} (S${action.payload.episode.seasonNumber}E${action.payload.episode.episodeNumber}) was added successfully!`
      )

      // const isFound = state.episodes.some((episode) => {
      //   if (episode.id === action.payload.episode.id) {
      //     return true
      //   }

      //   return false
      // })
      // console.log(isFound)
      // console.log(action.payload.episode)
      // if (!isFound) {
      //   console.log(111111111)
      //   const lastEpisodeIndex = state.episodes.findLastIndex(
      //     (episode) =>
      //       episode.seasonNumber === action.payload.episode.seasonNumber
      //   )
      //   console.log("lastIndex: ", lastEpisodeIndex)
      //   state.episodes.splice(lastEpisodeIndex + 1, 0, action.payload.episode)
      // }
    },
    updateEpisode: (state, action) => {
      toast.success(
        `Episode ${action.payload.episode.name} (S${action.payload.episode.seasonNumber}E${action.payload.episode.episodeNumber}) was updated successfully!`
      )
      // state.episodes.forEach((episode, i) => {
      //   if (episode.id === action.payload.episode.id) {
      //     state.episodes[i] = action.payload.episode
      //   }
      // })
    },
    deleteProviderContent: (state, action) => {
      if (action.payload.mediaType === "episode") {
        // const remainingEpisodes = state.episodes.filter(
        //   (episode) => episode.id !== action.payload.id
        // )
        // state.episodes = remainingEpisodes

        toast.success(
          `Episode ${
            state.episodes[action.payload.id].name
          } was deleted successfully!`
        )

        delete state.episodes[action.payload.id]
      }

      if (action.payload.mediaType === "show") {
        toast.success(
          `Show ${
            state.shows[action.payload.id].name
          } was deleted successfully!`
        )

        delete state.shows[action.payload.id]
      }

      // if (action.payload.mediaType === "show") {
      //   const remainingShows = state.shows.filter(
      //     (show) => show.id !== action.payload.id
      //   )
      //   state.shows = remainingShows
      //   toast.success(`Show was deleted successfully!`)
      // }

      // const remainingSources = state.sources.filter(
      //   (source) => source.id !== action.payload.id
      // )
      // state.sources = remainingSources
    }
  }
})

export const {
  searchShows,
  getProviderShows,
  getShowDetails,
  getShowSources,
  addTmdbShow,
  getProviderEpisodes
} = showSlice.actions

export default showSlice.reducer
