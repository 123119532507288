// ** React Imports
import { Fragment, useState, useRef, useEffect } from "react"
import { Link } from "react-router-dom"
import { Power, User } from "react-feather"
import { disconnect, send } from "@store/websocket"
import { useDispatch, useSelector } from "react-redux"

// ** Third Party Components
import classnames from "classnames"
import PerfectScrollbar from "react-perfect-scrollbar"

// ** Vertical Menu Components
import VerticalMenuHeader from "./VerticalMenuHeader"
import VerticalNavMenuItems from "./VerticalNavMenuItems"

const Sidebar = (props) => {
  // ** Props
  const {
    menuCollapsed,
    routerProps,
    menu,
    currentActiveItem,
    skin,
    menuData
  } = props

  // ** States
  const [groupOpen, setGroupOpen] = useState([])
  const [groupActive, setGroupActive] = useState([])
  const [currentActiveGroup, setCurrentActiveGroup] = useState([])
  const [activeItem, setActiveItem] = useState(null)

  const [userData] = useState(null)
  const storeIsLoggedin = useSelector((state) => state.socket.isLoggedin)
  const storeUserData = useSelector((state) => state.auth.userData)

  const dispatch = useDispatch()

  //** ComponentDidMount
  // useEffect(() => {
  //   if (isUserLoggedIn() !== null) {
  //     setUserData(JSON.parse(localStorage.getItem('userData')))
  //   }
  // }, [])

  useEffect(() => {
    if (storeIsLoggedin) {
      const message = {
        event: "action",
        params: {
          action: "getUser"
        }
      }

      dispatch(send(JSON.stringify(message)))
    }
  }, [storeIsLoggedin])

  // ** Menu Hover State
  const [menuHover, setMenuHover] = useState(false)

  // ** Ref
  const shadowRef = useRef(null)

  // ** Function to handle Mouse Enter
  const onMouseEnter = () => {
    setMenuHover(true)
  }

  return (
    <Fragment>
      <div
        className={classnames(
          "main-menu menu-fixed menu-accordion menu-shadow",
          {
            expanded: menuHover || menuCollapsed === false,
            "menu-light": skin !== "semi-dark" && skin !== "dark",
            "menu-dark": skin === "semi-dark" || skin === "dark"
          }
        )}
        onMouseEnter={onMouseEnter}
        onMouseLeave={() => setMenuHover(false)}
      >
        {menu ? (
          menu
        ) : (
          <Fragment>
            {/* Vertical Menu Header */}
            <VerticalMenuHeader
              setGroupOpen={setGroupOpen}
              menuHover={menuHover}
              {...props}
            />
            {/* Vertical Menu Content */}
            <div className="main-menu-content">
              <ul className="navigation navigation-main">
                <VerticalNavMenuItems
                  items={menuData}
                  menuData={menuData}
                  menuHover={menuHover}
                  groupOpen={groupOpen}
                  activeItem={activeItem}
                  groupActive={groupActive}
                  currentActiveGroup={currentActiveGroup}
                  routerProps={routerProps}
                  setGroupOpen={setGroupOpen}
                  menuCollapsed={menuCollapsed}
                  setActiveItem={setActiveItem}
                  setGroupActive={setGroupActive}
                  setCurrentActiveGroup={setCurrentActiveGroup}
                  currentActiveItem={currentActiveItem}
                />
              </ul>
              <ul className="userInfo">
                <li className="nav-item list-header">
                  <User size={20} className="ms-1 item-icon"/> {storeUserData?.username}
                </li>
                <li className="nav-item list-item">
                  <button
                    className="btn border-0"
                    onClick={() => dispatch(disconnect())}
                  ><Power size={20} className="item-icon"/> Logout
                  </button>
                </li>
              </ul>
            </div>
          </Fragment>
        )}
      </div>
    </Fragment>
  )
}

export default Sidebar
