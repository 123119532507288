// ** Reducers Imports
import navbar from "./navbar"
import layout from "./layout"
import auth from "./authentication"
import socket from "./websocket"
import collection from "./collection"
import movie from "./movie"
import show from "./show"
import common from "./common"
import server from "./contentserver"
import stats from "./statistics"
import withdraw from "./withdraw"

const rootReducer = {
  auth,
  socket,
  navbar,
  layout,
  collection,
  movie,
  show,
  common,
  server,
  stats,
  withdraw
}

export default rootReducer
