// App Host Config
const hostConfig = {

    // WEB SOCKET BASE URL
    BASE_URL_SOCKET: 
        process.env.REACT_APP_IS_USING_TEST_SERVER === 'true' ? (
            "ws://10.0.0.1:8123"
            //"ws://10.0.0.2:8080"
        ) : (
            "wss://api.maxplay.one"
        ),

    // HTTP BASE URL
    BASE_URL_HTTP: 
        process.env.REACT_APP_IS_USING_TEST_SERVER === 'true' ? (
            "http://10.0.0.1:8123"
            //"http://10.0.0.2:8080"
        ) : (
            "https://api.maxplay.one"
        )
    
  }
  
  export default hostConfig
