// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const serversSlice = createSlice({
  name: "server",
  initialState: {},

  reducers: {
    getContentServers: (state, action) => {
      // state.servers = action.payload.servers || []

      const servers = action.payload.servers ? [...action.payload.servers] : []
      state.servers = servers.sort((a, b) => {
        const nameA = a.name.toUpperCase() // ignore upper and lowercase
        const nameB = b.name.toUpperCase() // ignore upper and lowercase
        if (nameA < nameB) {
          return -1
        }
        if (nameA > nameB) {
          return 1
        }

        // names must be equal
        return 0
      })
    },
    addContentServer: (state, action) => {
      state.servers.unshift(action.payload.server)
      toast.success(
        `Server ${action.payload.server.name} was added successfully!`
      )
    },
    updateContentServer: (state, action) => {
      if (action.payload.server.id) {
        state.servers.forEach((server, i) => {
          if (server.id === action.payload.server.id) {
            state.servers[i] = action.payload.server
          }
        })
      }
      state.serverId = action.payload.server.id
      toast.success(
        `Server ${action.payload.server.name} was updated successfully!`
      )
    },
    deleteContentServer: (state, action) => {
      const remainingServers = state.servers.filter(
        (server) => server.id !== action.payload.id
      )
      state.servers = remainingServers
      toast.success(`Server was deleted successfully!`)
    }
  }
})

export const { getContentServers } = serversSlice.actions

export default serversSlice.reducer
