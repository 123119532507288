// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const collectionSlice = createSlice({
  name: "collection",
  initialState: {
    success: null
  },

  reducers: {
    // isLoading: (state, action) => {
    //   state.isLoading = action.payload
    // },
    createCollection: (state, action) => {
      state.collections = {
        [action.payload.collection.id]: action.payload.collection,
        ...state.collections
      }
      // state.success = `createCollection_${action.payload.messageId}`
      toast.success(
        `Collection ${action.payload.collection.name} was created successfully!`
      )
    },
    createChannel: (state, action) => {
      // state.channels.unshift(action.payload.channel)

      state.channels = {
        ...state.channels,
        [action.payload.channel.id]: action.payload.channel
      }
      // state.success = `createChannel_${action.payload.messageId}`
      toast.success(
        `Channel ${action.payload.channel.name} was created successfully!`
      )
    },
    getProviderCollections: (state, action) => {
      const collections = [...action.payload.collections]
      state.collections = {}
      collections
        .sort((a, b) => {
          const nameA = a.name.toUpperCase() // ignore upper and lowercase
          const nameB = b.name.toUpperCase() // ignore upper and lowercase
          if (nameA < nameB) {
            return -1
          }
          if (nameA > nameB) {
            return 1
          }

          // names must be equal
          return 0
        })
        .forEach((collection) => {
          state.collections[collection.id] = collection
        })

      // state.success = `getProviderCollections_${action.payload.messageId}`
    },
    getCollectionDetails: (state, action) => {
      state.selectedCollection = action.payload.collection
      // state.success = `getCollectionDetails${action.payload.messageId}`
    },
    updateCollection: (state, action) => {
      state.selectedCollection = action.payload.collection
      // state.success = `updateCollection_${action.payload.messageId}`
      if (action.payload.collection) {
        toast.success(
          `Collection ${action.payload.collection.name} was updated successfully!`
        )
      }
    },
    updateChannelPositions: (state, action) => {
      toast.success(`Channels' positions were updated successfully!`)
      // state.success = `updateChannelPositions_${action.payload.messageId}`
    },
    updateChannel: (state, action) => {
      // const index = state.channels.findIndex((channel) => {
      //   return channel.id === action.payload.channel.id
      // })

      state.channels[action.payload.channel.id] = action.payload.channel
      toast.success(
        `Channel ${action.payload.channel.name} was updated successfully!`
      )
      // state.success = `updateChannel${action.payload.messageId}`
    },
    deleteCollection: (state, action) => {
      toast.success(
        `Collection ${
          state.collections[action.payload.id].name
        } was deleted successfully!`
      )

      delete state.collections[action.payload.id]
    },
    deleteChannel: (state, action) => {
      toast.success(
        `Channel ${
          state.channels[action.payload.id].name
        } was deleted successfully!`
      )
      delete state.channels[action.payload.id]
    },
    // collectionIndexToDelete: (state, action) => {
    //   state.collections.splice(action.payload, 1)
    // },
    // channelIndexToDelete: (state, action) => {
    //   state.channels.splice(action.payload, 1)
    // },

    getCollectionChannels: (state, action) => {
      if (action.payload.channels === null) {
        state.channels = action.payload.channels
      }
      if (action.payload.channels !== null) {
        state.channels = {}

        action.payload.channels.forEach((channel) => {
          state.channels[channel.id] = channel
        })
      }
    }
  }
})

export const {
  createCollection,
  getCollections,
  getCollectionDetails,
  collectionIndexToDelete,
  channelIndexToDelete,
  getCollectionChannels,
  updateCollection
} = collectionSlice.actions

export default collectionSlice.reducer
