// ** React Imports
import { Fragment, useState, useEffect } from "react"
import { useDispatch, useSelector } from "react-redux"
import { disconnect, send } from "@store/websocket"
// ** Dropdowns Imports
import UserDropdown from "./UserDropdown"

// ** Third Party Components
import { Sun, Moon, User } from "react-feather"

// ** Reactstrap Imports
import { NavItem, NavLink } from "reactstrap"

const NavbarUser = (props) => {
  // ** Props
  const { skin, setSkin } = props

  const [userData] = useState(null)
  const storeIsLoggedin = useSelector((state) => state.socket.isLoggedin)
  const storeUserData = useSelector((state) => state.auth.userData)

  const dispatch = useDispatch()

  useEffect(() => {
    if (storeIsLoggedin) {
      const message = {
        event: "action",
        params: {
          action: "getUser"
        }
      }

      dispatch(send(JSON.stringify(message)))
    }
  }, [storeIsLoggedin])

  // ** Function to toggle Theme (Light/Dark)
  const ThemeToggler = () => {
    if (skin === "dark") {
      return <Sun className="ficon" onClick={() => setSkin("light")} />
    } else {
      return <Moon className="ficon" onClick={() => setSkin("dark")} />
    }
  }

  return (
    <Fragment>
      {/* <div className='bookmark-wrapper d-flex align-items-center'>
        <NavItem className='d-none d-lg-block'>
          <NavLink className='nav-link-style'>
            <ThemeToggler />
          </NavLink>
        </NavItem>
      </div> */}
      <ul className="nav navbar-nav align-items-center ms-auto">
        {/* <UserDropdown /> */}
        <li className="dropdown-user nav-item me-1">
          <div className="user-nav d-sm-flex">
            <span className="user-name fw-bold">
              {/* {(userData && userData["username"]) || "Teodor"} */}

              <span>
                <User size={20} className="ms-1" /> {storeUserData?.username}
              </span>
            </span>
            {/* <span className="user-status">
            {(userData && userData.role) || "Admin"}
          </span> */}
          </div>
        </li>
      </ul>
    </Fragment>
  )
}
export default NavbarUser
