// ** Router Import
import Router from "./router/Router"

import { useEffect } from "react"
import { connect } from "@store/websocket"
import { useDispatch } from "react-redux"

const App = (props) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (localStorage.getItem("jwt")) {
      dispatch(connect())
    }
  }, [])
  return <Router />
}

export default App
