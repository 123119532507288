import { lazy } from "react"

// ** Document title
const TemplateTitle = "%s - Stream React Admin Template"

// ** Default Route
const DefaultRoute = "/dashboard"

// ** Merge Routes
const Routes = [
  // {
  //   path: "//",
  //   exact: true,
  //   component: lazy(() => import("../../views/Home")),
  //   layout: "BlankLayout",
  //   meta: {
  //     publicRoute: true
  //   }
  // },
  {
    path: "//",
    exact: true,
    component: lazy(() => import("../../views/HomeThree")),
    layout: "BlankLayout",
    meta: {
      publicRoute: true
    }
  },
  {
    path: "/dashboard",
    exact: true,

    component: lazy(() => import("../../views/dashboard/Dashboard"))
  },
  {
    path: "/dashboard/commission/:commissionId",
    exact: true,

    component: lazy(() => import("../../views/dashboard/CommissionDetails"))
  },
  {
    path: "/login",
    component: lazy(() => import("../../views/auth/Login")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/register",
    component: lazy(() => import("../../views/auth/Register")),
    layout: "BlankLayout",
    meta: {
      authRoute: true
    }
  },
  {
    path: "/reset-password",
    component: lazy(() => import("../../views/auth/ResetPassword")),
    layout: "BlankLayout",
    exact: true,

    meta: {
      authRoute: true
    }
  },
  {
    path: "/registered-successfully",
    exact: true,
    component: lazy(() => import("../../views/auth/RegisteredSuccessfully")),
    layout: "BlankLayout"
  },
  {
    path: "/error",
    component: lazy(() => import("../../views/Error")),
    layout: "BlankLayout"
  },
  {
    path: "/second-page",
    component: lazy(() => import("../../views/SecondPage"))
  },

  {
    path: "/tv-channels/collections",
    exact: true,
    component: lazy(() => import("../../views/tvChannels/Collections"))
  },

  {
    path: "/tv-channels/collections/:id",
    exact: true,
    component: lazy(() => import("../../views/tvChannels/EditCollection"))
  },
  {
    path: "/tv-channels/create-collection",
    component: lazy(() => import("../../views/tvChannels/CreateCollection"))
  },
  {
    path: "/tv-channels/create-channel",
    exact: true,
    component: lazy(() => import("../../views/tvChannels/CreateChannel"))
  },
  {
    path: "/tv-channels/edit/:collectionId/:channelId",
    exact: true,
    component: lazy(() => import("../../views/tvChannels/UpdateChannel"))
  },
  {
    path: "/movies",
    exact: true,
    component: lazy(() => import("../../views/movies/Movies"))
  },
  {
    path: "/movies/addMovie",
    exact: true,
    component: lazy(() =>
      import("../../views/movies/addMovieSteps/AddMovieAllStepsWizard")
    )
  },
  {
    path: "/tvShows",
    exact: true,
    component: lazy(() => import("../../views/tvshows/TvShows.jsx"))
  },
  {
    path: "/tvShows/addTvShow",
    exact: true,
    component: lazy(() => import("../../views/tvshows/AddTvShowAllStepsWizard"))
  },
  {
    path: "/tvShows/edit/:tvShowId",
    exact: true,
    component: lazy(() => import("../../views/tvshows/EditTvShow"))
  },
  {
    path: "/servers",
    exact: true,
    component: lazy(() => import("../../views/servers/Servers.jsx"))
  },
  {
    path: "/wallet",
    exact: true,
    component: lazy(() => import("../../views/withdraw/Withdraw.jsx"))
  }
]

export { DefaultRoute, TemplateTitle, Routes }
