// ** React Imports
import { Suspense, lazy, Fragment, useEffect } from "react"
import { useSelector, useDispatch } from "react-redux"
import { success, errorMessage } from "@store/websocket"

// ** Utils
import { useLayout } from "@hooks/useLayout"
import { useRouterTransition } from "@hooks/useRouterTransition"

// ** Custom Components
import LayoutWrapper from "@layouts/components/layout-wrapper"

// ** Router Components
import {
  BrowserRouter as AppRouter,
  Route,
  Switch,
  Redirect
} from "react-router-dom"

// ** Routes & Default Routes
import { DefaultRoute, Routes } from "./routes"

// ** Layouts
import BlankLayout from "@layouts/BlankLayout"
import VerticalLayout from "@src/layouts/VerticalLayout"
import HorizontalLayout from "@src/layouts/HorizontalLayout"
import SpinnerComponent from "../@core/components/spinner/Fallback-spinner"

const Router = () => {
  // ** Hooks
  const { layout, setLayout, setLastLayout } = useLayout()
  const { transition, setTransition } = useRouterTransition()
  // const store = useSelector((state) => state)

  const storeIsLoggedIn = useSelector((state) => state.socket.isLoggedin)

  // useEffect(() => {
  //   console.log("router is mounted")
  // }, [])

  // useEffect(() => {
  //   console.log("router is rendered")
  // })

  // ** Default Layout
  const DefaultLayout =
    layout === "horizontal" ? "HorizontalLayout" : "VerticalLayout"

  // ** All of the available layouts
  const Layouts = { BlankLayout, VerticalLayout, HorizontalLayout }

  // ** Current Active Item
  const currentActiveItem = null

  // ** Return Filtered Array of Routes & Paths
  const LayoutRoutesAndPaths = (layout) => {
    const LayoutRoutes = []
    const LayoutPaths = []

    if (Routes) {
      Routes.filter((route) => {
        // ** Checks if Route layout or Default layout matches current layout
        if (
          route.layout === layout ||
          (route.layout === undefined && DefaultLayout === layout)
        ) {
          LayoutRoutes.push(route)
          LayoutPaths.push(route.path)
        }
      })
    }

    return { LayoutRoutes, LayoutPaths }
  }

  const NotAuthorized = lazy(() => import("@src/views/NotAuthorized"))

  // ** Init Error Component
  const Error = lazy(() => import("@src/views/Error"))

  /**
   ** CheckedRoute Route Component Checks for Login & User Role and then redirects to the route
   */
  const CheckedRoute = (props) => {
    const route = props.route
    // console.log("route: ", props)
    // console.log("isLoggedin: ", storeIsLoggedIn)

    // console.log(route)

    if (route.meta && route.meta.authRoute && storeIsLoggedIn === false) {
      // console.log(1)
      return <SpinnerComponent />
    }

    if (
      localStorage.getItem("secretKey") &&
      localStorage.getItem("jwt") &&
      storeIsLoggedIn
    ) {
      // console.log(2)
      return <Redirect from="/register" to="/registered-successfully" />
    }

    if (storeIsLoggedIn === null && route.meta === undefined) {
      // console.log(3)
      return <Redirect to="/login" />
    }

    if (
      storeIsLoggedIn === null &&
      route.meta &&
      !route.meta.authRoute &&
      !route.meta.publicRoute
    ) {
      // console.log(4)
      return <Redirect to="/login" />
    }

    if (route.meta && route.meta.authRoute && storeIsLoggedIn) {
      // console.log(5)
      return <Redirect to="/dashboard" />
    }

    return <route.component {...props} />
    // return <Route render={() => <route.component {...props} />} />
  }

  // ** Return Route to Render
  const ResolveRoutes = () => {
    return Object.keys(Layouts).map((layout, index) => {
      // ** Convert Layout parameter to Layout Component
      // ? Note: make sure to keep layout and component name equal

      const LayoutTag = Layouts[layout]

      // ** Get Routes and Paths of the Layout
      const { LayoutRoutes, LayoutPaths } = LayoutRoutesAndPaths(layout)

      // ** We have freedom to display different layout for different route
      // ** We have made LayoutTag dynamic based on layout, we can also replace it with the only layout component,
      // ** that we want to implement like VerticalLayout or HorizontalLayout
      // ** We segregated all the routes based on the layouts and Resolved all those routes inside layouts

      // ** RouterProps to pass them to Layouts
      const routerProps = {}
      return (
        <Route path={LayoutPaths} key={index}>
          <LayoutTag
            layout={layout}
            setLayout={setLayout}
            transition={transition}
            routerProps={routerProps}
            setLastLayout={setLastLayout}
            setTransition={setTransition}
            currentActiveItem={currentActiveItem}
          >
            <Switch>
              {LayoutRoutes.map((route) => {
                return (
                  // As Route component has a key prop it recreates the component every time and do not save local state
                  <Route
                    key={route.path}
                    path={route.path}
                    exact={route.exact === true}
                    render={(props) => {
                      // ** Assign props to routerProps
                      Object.assign(routerProps, {
                        ...props,
                        meta: route.meta
                      })

                      return (
                        <Fragment>
                          {/* Layout Wrapper to add classes based on route's layout, appLayout and className */}
                          {/* because "/registered-successfully" */}
                          {route.layout === "BlankLayout" &&
                          route.path !== "/registered-successfully" ? (
                            <Fragment>
                              {/* <route.component {...props} /> */}
                              <CheckedRoute route={route} {...props} />
                            </Fragment>
                          ) : (
                            <LayoutWrapper
                              layout={DefaultLayout}
                              transition={transition}
                              setTransition={setTransition}
                              /* Conditional props */
                              /*eslint-disable */
                              {...(route.appLayout
                                ? {
                                    appLayout: route.appLayout
                                  }
                                : {})}
                              {...(route.meta
                                ? {
                                    routeMeta: route.meta
                                  }
                                : {})}
                              {...(route.className
                                ? {
                                    wrapperClass: route.className
                                  }
                                : {})}
                              /*eslint-enable */
                            >
                              {/* <route.component {...props} /> */}

                              {storeIsLoggedIn ? (
                                <Suspense fallback={null}>
                                  {/* As route.component has no key prop it preserves the component state between renders and do not reset forms state*/}

                                  <route.component route={route} {...props} />
                                </Suspense>
                              ) : (
                                <CheckedRoute route={route} {...props} />
                              )}
                            </LayoutWrapper>
                          )}
                        </Fragment>
                      )
                    }}
                  />
                )
              })}
            </Switch>
          </LayoutTag>
        </Route>
      )
    })
  }

  return (
    <AppRouter basename={"/"}>
      <Switch>
        {/* If user is logged in Redirect user to DefaultRoute else to login */}

        {/* <Route
          exact
          path="/"
          render={() => {
            if (storeIsLoggedIn) {
              return <Redirect to={DefaultRoute} />
            }
            return <Redirect to={Home} />
          }}
        /> */}
        {/* Not Auth Route */}

        {/* <Route
          exact
          path="/misc/not-authorized"
          render={() => (
            <Layouts.BlankLayout>
              <NotAuthorized />
            </Layouts.BlankLayout>
          )}
        /> */}
        {ResolveRoutes()}
        {/* NotFound Error page */}
        <Route path="*" component={Error} />
      </Switch>
    </AppRouter>
  )
}

export default Router
