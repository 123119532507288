// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// ** UseJWT import to get config
// import useJwt from "@src/auth/jwt/useJwt"

// const config = useJwt.jwtConfig

// const initialUser = () => {
//   const item = window.localStorage.getItem("userData")
//   //** Parse stored json or if none return initialValue
//   return item ? JSON.parse(item) : {}
// }

// This slice not used.It is replaced with websocketSlice

export const authSlice = createSlice({
  name: "authentication",
  initialState: {},
  reducers: {
    // handleRegister: (state, action) => {
    //   state.jwt = action.payload.jwt
    //   state.secretKey = action.payload.secretKey
    //   localStorage.setItem("jwt", action.payload.jwt)
    // },
    // handleLogin: (state, action) => {
    //   state.jwt = action.payload.jwt
    //   localStorage.setItem("jwt", action.payload.jwt)
    // },
    // handleLogout: () => {
    //   localStorage.removeItem("jwt")
    // }

    getUser: (state, action) => {
      state.userData = action.payload.user
      // state.jwt = action.payload.jwt
      // state.secretKey = action.payload.secretKey
      // localStorage.setItem("jwt", action.payload.jwt)
    }
  }
})

export const { getUser } = authSlice.actions

export default authSlice.reducer
