// import baseUrl from "../../configs/axios"
import { toast } from "react-toastify"
import hostConfig from "@configs/host"

let websocket

/**
 * https://gist.github.com/dmichael/9dc767fca93624df58b423d01e485402
 * An example middleware to handle WebSocket connections.
 * NB: There is no exception handling!
 */

const noSuccessNeeded = [
  "getCollectionDetails",
  "getProviderCollections",
  "getProviderShows",
  "deleteCollection",
  "getCollectionChannels",
  "getCollectionDetails",
  "getListOfCountries",
  "searchMovies",
  "searchShows",
  "getProviderMovies",
  "getMovieGenres",
  "addEpisode",
  "getProviderEpisodes",
  "getProviderSources",
  "getContentServers",
  "deleteSource",
  "getShowDetails",
  "getPlans",
  "getUser"
]

const socketMiddleware =
  ({ getState, dispatch }) =>
  (next) =>
  (action) => {
    switch (action.type) {
      // User request to connect
      case "websocket/connect":
        const jwt = localStorage.getItem("jwt")
        if (jwt === null) {
          window.location.reload()
        }
        // console.log(jwt)
        // Configure the object
        websocket = new WebSocket(
          `${hostConfig.BASE_URL_SOCKET}/ws?token=${jwt}`
        )

        // Attach the callbacks
        websocket.onopen = () => {
          // toast.success(`You are connected successfully!`)
        }
        websocket.onclose = (event) => {
          if (event.code === 1005) {
            console.log("Socket is closed!")

            // dispatch({ type: "websocket/onclose" })
          } else {
            console.log(
              "Socket is closed Unexpectedly. Reconnect will be attempted in 4 second.",
              event.reason
            )
            toast.error(`Connection failed!`)

            setTimeout(() => {
              dispatch({ type: "websocket/connect" })
            }, 5000)
          }
        }

        websocket.onerror = (error) => {
          console.error(
            "Socket encountered error: ",
            error.message,
            "Closing socket"
          )
          websocket.close()
        }
        websocket.onmessage = (event) => {
          if (event.data.includes("primus::ping::")) {
            websocket.send(event.data.replace("ping", "pong"))
          } else {
            const message = JSON.parse(event.data)
            // console.log(message)
            if (message.welcome) {
              dispatch({ type: "websocket/isConnected" })
            }
            if (message.connectionError === "Invalid token.") {
              dispatch({ type: "websocket/disconnect" })
            }
            if (message.error) {
              // dispatch({ type: "websocket/clear" })
              // dispatch({
              //   type: `websocket/errorMessage`,
              //   payload: message.error
              // })
              // setTimeout(() => {
              //   dispatch({ type: "websocket/clear" })
              // }, 2000)
              dispatch({
                type: "websocket/error",
                payload: message.error
              })
              toast.error(`${message.error}`)
              return null
            }
            if (message.action) {
              dispatch({
                type: "websocket/success",
                payload: message
              })
            }

            // if (message.action && !noSuccessNeeded.includes(message.action)) {
            //   dispatch({
            //     type: "websocket/success",
            //     payload: true
            //   })

            //   setTimeout(() => {
            //     dispatch({ type: "websocket/clear" })
            //   }, 2000)
            // }

            if (message.action) {
              if (message.action === "getUser") {
                dispatch({
                  type: `authentication/${message.action}`,
                  payload: message
                })
                return null
              }
              if (
                message.action.toLowerCase().includes("collection") ||
                message.action.toLowerCase().includes("channel")
              ) {
                dispatch({
                  type: `collection/${message.action}`,
                  payload: message
                })
                return null
              } else if (message.action.toLowerCase().includes("movie")) {
                dispatch({
                  type: `movie/${message.action}`,
                  payload: message
                })
                return null
              } else if (
                message.action.toLowerCase().includes("show") ||
                message.action.toLowerCase().includes("episode")
              ) {
                dispatch({
                  type: `show/${message.action}`,
                  payload: message
                })
                return null
              } else if (
                message.action.toLowerCase().includes("contentserver")
              ) {
                dispatch({
                  type: `server/${message.action}`,
                  payload: message
                })
                return null
              } else if (message.action.toLowerCase().includes("stats")) {
                dispatch({
                  type: `statistics/${message.action}`,
                  payload: message
                })
                return null
              } else if (message.action.toLowerCase().includes("withdraw")) {
                dispatch({
                  type: `withdraw/${message.action}`,
                  payload: message
                })
                return null
              } else if (message.action === "deleteProviderContent") {
                if (
                  message.mediaType === "episode" ||
                  message.mediaType === "show"
                ) {
                  dispatch({
                    type: `show/${message.action}`,
                    payload: message
                  })
                  return null
                }
                if (message.mediaType === "movie") {
                  dispatch({
                    type: `movie/${message.action}`,
                    payload: message
                  })
                  return null
                }
              } else {
                dispatch({
                  type: `common/${message.action}`,
                  payload: message
                })
                return null
              }
            }
          }
        }

        break

      // User request to send a message
      case "websocket/send":
        websocket.send(action.payload)
        break

      // User request to disconnect
      case "websocket/disconnect":
        if (websocket) {
          websocket.close()
        }
        localStorage.removeItem("jwt")
        localStorage.clear()
        sessionStorage.clear()
        window.location.reload()
        break

      default:
      // We don't really need the default but ...
      // console.log("default case ( entered no middleware case)")
    }

    return next(action)
  }

export default socketMiddleware
