// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"

// If  websocket response message returns action property:

export const statsSlice = createSlice({
  name: "statistics",
  initialState: {},

  reducers: {
    getProviderStats: (state, action) => {
      state.statistics = action.payload.stats
      state.balance = action.payload.balance
      // state.commissions = action.payload.commissions
      state.commissions = {}
      action.payload.commissions.forEach((commission, i) => {
        state.commissions[commission.id] = commission
      })
    },
    getCommissionStats: (state, action) => {
      state.commissionDetails = action.payload.userStats
    }
  }
})

export const { getProviderStats } = statsSlice.actions

export default statsSlice.reducer
