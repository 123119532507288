import {
  Film,
  Home,
  Circle,
  Tv,
  Activity,
  Video,
  Server,
  DollarSign
} from "react-feather"

export default [
  // {
  //   id: 'home',
  //   title: 'Home',
  //   icon: <Home size={20} />,
  //   navLink: '/home'
  // },
  {
    id: "dashboard",
    title: "Dashboard",
    icon: <Activity size={20} />,
    // badge: "light-warning"
    // badgeText: "2"
    navLink: "/dashboard"
  },
  {
    id: "separator1",
    disabled: "true"
  },
  {
    id: "tvChannels",
    title: "TV",
    icon: <Tv size={20} />,
    navLink: "/tv-channels/collections"

    // children: [
    //   {
    //     id: "collections",
    //     title: "Collections",
    //     icon: <Circle size={12} />,
    //     navLink: "/tv-channels/collections"
    //   }

    // {
    //   id: "createCollection",
    //   title: "Create Collection",
    //   icon: <Circle size={12} />,
    //   navLink: "/tv-channels/create-collection"
    // },
    // {
    //   id: "createChannel",
    //   title: "Create Channel",
    //   icon: <Circle size={12} />,
    //   navLink: "/tv-channels/create-channel"
    // }
    // ]
  },
  {
    id: "movies",
    title: "Movies",
    icon: <Video size={20} />,

    navLink: "/movies"
  },
  {
    id: "tvShows",
    title: "Shows",
    icon: <Film size={20} />,

    navLink: "/tvshows"
  },
  {
    id: "servers",
    title: "Servers",
    icon: <Server size={20} />,

    navLink: "/servers"
  },
  {
    id: "separator2",
    disabled: "true"
  },
  {
    id: "wallet",
    title: "Wallet",
    icon: <DollarSign size={20} />,

    navLink: "/wallet"
  }
  // {
  //   id: "addTvShow",
  //   title: "Tv Shows",
  //   icon: <Video size={20} />,

  //   navLink: "/tvshows/addTvShow"
  // }
  // {
  //   id: "Movies",
  //   title: "Movies",
  //   icon: <Film size={20} />,
  //   badge: "light-warning",
  //   children: [
  //     {
  //       id: "addMovie",
  //       title: "Add movie",
  //       icon: <Circle size={12} />,
  //       navLink: "/movies/addMovie"
  //     }
  //   ]
  // }
]
