// ** Redux Imports
import { createSlice } from "@reduxjs/toolkit"
import { toast } from "react-toastify"

// If  websocket response message returns action property:

export const withdrawSlice = createSlice({
  name: "withdraw",
  initialState: {
    //   success: null
  },

  reducers: {
    // isLoading: (state, action) => {
    //   state.isLoading = action.payload
    // },

    getWithdrawals: (state, action) => {
      const withdrawals = [...action.payload.withdrawals]
      state.withdrawals = {}
      withdrawals.forEach((withdrawal) => {
        state.withdrawals[withdrawal.id] = withdrawal
      })
      state.balance = action.payload.balance
    },
    requestWithdraw: (state, action) => {
      toast.success("Your withdrawal was requested successfully!")
      state.withdrawals = {
        [action.payload.withdrawal.id]: action.payload.withdrawal,
        ...state.withdrawals
      }
      state.balance = action.payload.balance
    },
    cancelWithdraw: (state, action) => {
      state.withdrawals[action.payload.withdrawId].status = "Cancelled"
      // delete state.withdrawals[action.payload.id]
    }
  }
})

export const {
  createCollection,
  getCollections,
  getCollectionDetails,
  collectionIndexToDelete,
  channelIndexToDelete,
  getCollectionChannels,
  updateCollection,
  requestWithdraw
} = withdrawSlice.actions

export default withdrawSlice.reducer
